.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;
  position: static;
  height: max-content;
}
.editorClass {
  border: none !important;
  box-shadow: 0px 1px 7px 0px #e5e5e5 !important;
  border-radius: 10px;
  line-height: 1.5;
  position: static;
  top: 120px;
  flex-grow: 1;
}
.editorClass:focus {
  border: #d9d9d9;
}
.iconCtn {
  z-index: 200;
  padding: 5px;
  background-color: white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
.iconCtn > img {
  width: 40px;
  height: 40px;
  margin: 0;
}
@media screen and (max-width: 630px) {
  .container {
    overflow: scroll;
  }
}


