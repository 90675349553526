.container {
  background-color: rgba(0, 0, 0, 0.386);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "Inter", sans-serif;
}
.ctn {
  background-color: white;
  color: #283943;
  padding: 30px 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 500px;
  position: relative;
}
.ctn > button {
  background-color: transparent;
  outline: 0;
  border: 0;
  color: #283943;
  font-size: 18px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.ctn > h1 {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.buttons button {
  background-color: #4d5c64;
  padding: 10px;
  border: var(--white) 1px solid;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  min-width: 80px;
}
