.container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin: auto;
  background: #e7e6e6;
}
.links {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
}
.copyrightTxt {
  text-align: center;
  color: black;
  padding: 10px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  background: #e7e6e6;
}
.heading {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: black;
  font-weight: 400;
  font-size: 1.5rem;
}
.links a {
  text-decoration: none;
  color: black;
  font-family: Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 520px) {
  .container {
    flex-direction: column;
    row-gap: 20px;
  }
}
@media screen and (max-width: 382px) {
  .container {
    flex-direction: column;
    row-gap: 20px;
  }
  .links {
    column-gap: 10px;
  }
}
