.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.updateButton {
  width: 300px;
  padding: 10px;
  background-color: var(--primary300);
  color: white;
  font-size: 1.1rem;
  border: none;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.dataTableContainer {
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 90vw;
  margin: 20px auto;
}

.header {
  background-color: #3498db;
  color: #fff;
  font-weight: bold;
}

.row:hover {
  background-color: #ebf5ff;
}
.waitMessage {
  color: var(--primary);
  font-size: 1.4rem;
  font-weight: 800;
}
.updatingContaier {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
