.dataTableContainer {
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 90vw;
  margin: 20px auto;
}

.header {
  background-color: #3498db;
  color: #fff;
  font-weight: bold;
}

.row:hover {
  background-color: #ebf5ff;
}
