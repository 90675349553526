.container {
  background-color: rgba(0, 0, 0, 0.386);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "Inter", sans-serif;
}
.container div::-webkit-scrollbar {
  width: 12px;
}

.container div::-webkit-scrollbar-thumb {
  background-color: rgb(119 84 84 );
  border-radius: 6px;
  border: 3px solid rgb(119 84 84 );
}

.container div::-webkit-scrollbar-track {
  background-color: rgb(175, 175, 175);
}