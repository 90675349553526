.container {
  background-color: rgba(0, 0, 0, 0.386);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "Inter", sans-serif;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading svg {
  align-self: baseline;
}
.heading h3 {
  padding-top: 20px;
}
.formContent {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 10px 30px;
}

.container form::-webkit-scrollbar {
  width: 12px;
}

.container form::-webkit-scrollbar-thumb {
  background-color: rgb(119 84 84 );
  border-radius: 6px;
  border: 3px solid rgb(119 84 84 );
}

.container form::-webkit-scrollbar-track {
  background-color: rgb(175, 175, 175);
}

.container form {
  scrollbar-width: thin;
  scrollbar-color: rgb(119 84 84 ) #c2c2c2;
}
