.container {
  padding: 20px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #efefef;
}

.container h1 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.3rem;
  font-weight: 600;
  color: #222222;
}
.links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.links a {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: var(--primary);
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.links a:hover {
  color: var(--secondary);
  text-decoration: underline;
}
.awaiting {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}
.pendingLink {
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
}
