@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Roboto:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montaga&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  background-color: #fcfcff;
  touch-action: manipulation; /* For modern browsers */
  touch-action-delay: none; /* For iOS */
}

.jodit-react-container {
  border-radius: 6px !important;
}
.jodit-container {
  border-radius: 6px !important;
}
.jodit-workplace {
  border-radius: 6px !important;
}
.jodit-wysiwyg {
  padding: 5px;
  min-height: 90vh !important;
  resize: none;
  border-radius: 20px !important;
  scrollbar-width: thin;
  scrollbar-color: #f4f4f4 #999;
  /* Styles for Webkit-based browsers (Chrome, Safari) */
  scrollbar-width: thin;
  scrollbar-color: #f4f4f4 #999;

  /* Customize the scrollbar track and thumb */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;
  }
}

:root {
  --primary: #3e4581;
  --primary300: #ae67fa;
  --primary200: #eceeff;
  --primary100: #f8f3ff;
  --textGray: #adadad;
  --black: #343541;
  --white: #eceeff;
}

input[type="number"]:focus,
input[type="text"]:focus,
textarea:focus,
select:focus {
  border: #ff658a 1px solid !important;
}

@media screen and (max-width: 860px) {
  html {
    font-size: 13px;
  }
}

.react-datepicker__input-container input {
  width: 100%;
}

.Toastify__toast-body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .jodit-container {
    width: 100vw !important;
  }
}

/* .jodit-toolbar__box:not(:empty):not(:empty) {
  position: sticky !important;
  top: 72px !important;
} */