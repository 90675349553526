.container {
  background-color: rgba(0, 0, 0, 0.386);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: "Inter", sans-serif;
}
.ctn {
  background-color: #fefeff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  color: #4d5c64;
  padding: 30px 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 500px;
  position: relative;
  text-align: center;
}
.ctn > button {
  background-color: transparent;
  outline: 0;
  border: 0;
  color: white;
  font-size: 18px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.ctn > h1 {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.buttons {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.buttons button {
  background-color: #4d5c64;
  padding: 10px;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  width: 100%;
}
.inputContainer input {
  padding: 10px;
  outline: none;
  border: none;
  background-color: #3b57731a;
  border: 0.5px solid #4d5c64;
  border-radius: 4px;
  resize: none;
  width: 100%;
}
.loadingSpinner {
  align-self: center;
  justify-self: center;
}
